<template>
  <div>
    <!--    <expansion-panels-layout>-->
    <!-- projects header icon & title -->
    <!--      <template v-slot:header>-->
    <!--        <details-banner-layout>-->
    <!--          <template v-slot:avatar>-->
    <!--            <v-icon>{{ iconProjects }}</v-icon>-->
    <!--          </template>-->
    <!--          <template v-slot:default>-->
    <!--            {{ title }}-->
    <!--          </template>-->
    <!--        </details-banner-layout>-->
    <!--      </template>-->

    <!-- list projects -->
    <!--      <template v-slot:content>-->
    <template v-for="p in computedProjects">
      <v-row class="mt-2" dense :key="`project-${p.projectId}`">
        <v-col>
          <slot name="project" v-bind:project="p"></slot>
        </v-col>
      </v-row>
    </template>
    <!--      </template>-->
    <!--    </expansion-panels-layout>-->
  </div>
</template>

<script>
// design
import { iconProjects } from "@/design/icon/iconConst";

// model
import { projectLabel } from "@/model/workflow/project/projectModel";

export default {
  name: "ProjectsLayout",
  // components: {
  //   ExpansionPanelsLayout: () =>
  //     import("@/components/shared/core/expansionPanel/ExpansionPanelsLayout"),
  //   DetailsBannerLayout: () =>
  //     import("@/components/shared/core/banners/DetailsBannerLayout")
  // },
  props: {
    projects: undefined
  },
  data() {
    return {
      iconProjects: iconProjects
    };
  },
  computed: {
    /**
     * project Count
     * @return {number}
     */
    projectCount() {
      return this.projects?.length ?? 0;
    },

    /**
     * project tab title
     * @return {string}
     */
    title() {
      return this.projectCount <= 1
        ? `(${this.projectCount}) ${projectLabel}`
        : `(${this.projectCount}) ${projectLabel}s`;
    },

    /**
     * computed list of record's associated Projects
     * @return {{projectId: Number, creationDate: string, definitionId: Number, workflowInstanceId: Number, workflowTypeId: Number, projectName: String, recordId: Number, subject: String, owner: String, projectManagerId: Number, templateId: Number, templateName: String, workflowInstanceId: number, status: number, isOverdue: Boolean, isSuspended: false, canAccess: Boolean, canCancel: Boolean, canChangeOwnership: Boolean, canReassign: Boolean, canSuspend: Boolean}[]}
     */
    computedProjects() {
      return this.projects ?? [];
    }
  }
};
</script>
